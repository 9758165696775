import { useCallback, useState } from 'react';
import type { DefaultOptionType } from 'antd/lib/select';

// iot
import {
  getEventList,
  getPropertyList,
  getServiceList,
  getProductList,
  getProductTypeList,
  getModbusUpAndDownConfigList,
  getMqttUpAndDownConfigList,
  getHttpUpAndDownConfigList,
  getNetworkList,
} from '../services/Iot';

// import { addTreeRoot } from '@/utils/utils';

// INT32, INT64, FLOAT, DOUBLE, ENUM, BOOL, STRING, DATE
export default function useFarmlandM() {
  const functionTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'EVENT', label: '事件' },
    { id: '2', value: 'PROPERTY', label: '属性' },
    { id: '3', value: 'SERVICE', label: '服务' },
  ];
  const dataTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'INT32', label: 'int32(整数型)' },
    { id: '2', value: 'INT64', label: 'int64(长整数型)' },
    { id: '3', value: 'FLOAT', label: 'float(单精度浮点型)' },
    { id: '4', value: 'DOUBLE', label: 'double(双精度浮点型)' },
    { id: '5', value: 'ENUM', label: 'enum(枚举)' },
    { id: '6', value: 'BOOL', label: 'bool(布尔)' },
    { id: '7', value: 'STRING', label: 'string(字符串)' },
    { id: '8', value: 'DATE', label: 'date(时间)' },
  ];
  const eventTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'INFORMATION', label: '信息' },
    { id: '2', value: 'ALARM', label: '告警' },
    { id: '3', value: 'FAULT', label: '故障' },
  ];
  const callMethodEnums: DefaultOptionType[] = [
    { id: '1', value: 'ASYNCHRONOUS', label: '异步' },
    { id: '2', value: 'SYNCHRONIZATION', label: '同步' },
  ];
  const unitEnums: DefaultOptionType[] = [
    { id: 1, value: '微西每厘米 / uS/cm', label: '微西每厘米 / uS/cm' },
    { id: 2, value: '牛顿每库仑 / N/C', label: '牛顿每库仑 / N/C' },
    { id: 3, value: '伏特每米 / V/m', label: '伏特每米 / V/m' },
    { id: 4, value: '滴速 / ml/min', label: '滴速 / ml/min' },
    { id: 5, value: '血压 / mmHg', label: '血压 / mmHg' },
    { id: 6, value: '血糖 / mmol/L', label: '血糖 / mmol/L' },
    { id: 7, value: '毫米每秒 / mm/s', label: '毫米每秒 / mm/s' },
    { id: 8, value: '转每分钟 / turn/m', label: '转每分钟 / turn/m' },
    { id: 9, value: '次 / count', label: '次 / count' },
    { id: 10, value: '档 / gear', label: '档 / gear' },
    { id: 11, value: '步 / stepCount', label: '步 / stepCount' },
    { id: 12, value: '标准立方米每小时 / Nm3/h', label: '标准立方米每小时 / Nm3/h' },
    { id: 13, value: '千伏 / kV', label: '千伏 / kV' },
    { id: 14, value: '千伏安 / kVA', label: '千伏安 / kVA' },
    { id: 15, value: '千乏 / kvar', label: '千乏 / kvar' },
    { id: 16, value: '微瓦每平方厘米 / uw/cm²', label: '微瓦每平方厘米 / uw/cm²' },
    { id: 17, value: '只 / 只', label: '只 / 只' },
    { id: 18, value: '相对湿度 / %RH', label: '相对湿度 / %RH' },
    { id: 19, value: '立方米每秒 / m³/s', label: '立方米每秒 / m³/s' },
    { id: 20, value: '公斤每秒 / kg/s', label: '公斤每秒 / kg/s' },
    { id: 21, value: '转每分钟 / r/min', label: '转每分钟 / r/min' },
    { id: 22, value: '吨每小时 / t/h', label: '吨每小时 / t/h' },
    { id: 23, value: '千卡每小时 / KCAL/h', label: '千卡每小时 / KCAL/h' },
    { id: 24, value: '升每秒 / L/s', label: '升每秒 / L/s' },
    { id: 25, value: '兆帕 / Mpa', label: '兆帕 / Mpa' },
    { id: 26, value: '立方米每小时 / m³/h', label: '立方米每小时 / m³/h' },
    { id: 27, value: '千乏时 / kvarh', label: '千乏时 / kvarh' },
    { id: 28, value: '微克每升 / μg/L', label: '微克每升 / μg/L' },
    { id: 29, value: '千卡路里 / kcal', label: '千卡路里 / kcal' },
    { id: 30, value: '吉字节 / GB', label: '吉字节 / GB' },
    { id: 31, value: '兆字节 / MB', label: '兆字节 / MB' },
    { id: 32, value: '千字节 / KB', label: '千字节 / KB' },
    { id: 33, value: '字节 / B', label: '字节 / B' },
    { id: 34, value: '微克每平方分米每天 / μg/(d㎡·d)', label: '微克每平方分米每天 / μg/(d㎡·d)' },
    { id: 35, value: '百万分率 / ppm', label: '百万分率 / ppm' },
    { id: 36, value: '像素 / pixel', label: '像素 / pixel' },
    { id: 37, value: '照度 / Lux', label: '照度 / Lux' },
    { id: 38, value: '重力加速度 / grav', label: '重力加速度 / grav' },
    { id: 39, value: '分贝 / dB', label: '分贝 / dB' },
    { id: 40, value: '百分比 / %', label: '百分比 / %' },
    { id: 41, value: '流明 / lm', label: '流明 / lm' },
    { id: 42, value: '比特 / bit', label: '比特 / bit' },
    { id: 43, value: '克每毫升 / g/mL', label: '克每毫升 / g/mL' },
    { id: 44, value: '克每升 / g/L', label: '克每升 / g/L' },
    { id: 45, value: '毫克每升 / mg/L', label: '毫克每升 / mg/L' },
    { id: 46, value: '微克每立方米 / μg/m³', label: '微克每立方米 / μg/m³' },
    { id: 47, value: '毫克每立方米 / mg/m³', label: '毫克每立方米 / mg/m³' },
    { id: 48, value: '克每立方米 / g/m³', label: '克每立方米 / g/m³' },
    { id: 49, value: '千克每立方米 / kg/m³', label: '千克每立方米 / kg/m³' },
    { id: 50, value: '纳法 / nF', label: '纳法 / nF' },
    { id: 51, value: '皮法 / pF', label: '皮法 / pF' },
    { id: 52, value: '微法 / μF', label: '微法 / μF' },
    { id: 53, value: '法拉 / F', label: '法拉 / F' },
    { id: 54, value: '欧姆 / Ω', label: '欧姆 / Ω' },
    { id: 55, value: '微安 / μA', label: '微安 / μA' },
    { id: 56, value: '毫安 / mA', label: '毫安 / mA' },
    { id: 57, value: '千安 / kA', label: '千安 / kA' },
    { id: 58, value: '安培 / A', label: '安培 / A' },
    { id: 59, value: '毫伏 / mV', label: '毫伏 / mV' },
    { id: 60, value: '伏特 / V', label: '伏特 / V' },
    { id: 61, value: '毫秒 / ms', label: '毫秒 / ms' },
    { id: 62, value: '秒 / s', label: '秒 / s' },
    { id: 63, value: '分钟 / min', label: '分钟 / min' },
    { id: 64, value: '小时 / h', label: '小时 / h' },
    { id: 65, value: '日 / day', label: '日 / day' },
    { id: 66, value: '周 / week', label: '周 / week' },
    { id: 67, value: '月 / month', label: '月 / month' },
    { id: 68, value: '年 / year', label: '年 / year' },
    { id: 69, value: '节 / kn', label: '节 / kn' },
    { id: 70, value: '千米每小时 / km/h', label: '千米每小时 / km/h' },
    { id: 71, value: '米每秒 / m/s', label: '米每秒 / m/s' },
    { id: 72, value: '秒 / ″', label: '秒 / ″' },
    { id: 73, value: '分 / ′', label: '分 / ′' },
    { id: 74, value: '度 / °', label: '度 / °' },
    { id: 75, value: '弧度 / rad', label: '弧度 / rad' },
    { id: 76, value: '赫兹 / Hz', label: '赫兹 / Hz' },
    { id: 77, value: '微瓦 / μW', label: '微瓦 / μW' },
    { id: 78, value: '毫瓦 / mW', label: '毫瓦 / mW' },
    { id: 79, value: '千瓦特 / kW', label: '千瓦特 / kW' },
    { id: 80, value: '瓦特 / W', label: '瓦特 / W' },
    { id: 81, value: '卡路里 / cal', label: '卡路里 / cal' },
    { id: 82, value: '千瓦时 / kW·h', label: '千瓦时 / kW·h' },
    { id: 83, value: '瓦时 / Wh', label: '瓦时 / Wh' },
    { id: 84, value: '电子伏 / eV', label: '电子伏 / eV' },
    { id: 85, value: '千焦 / kJ', label: '千焦 / kJ' },
    { id: 86, value: '焦耳 / J', label: '焦耳 / J' },
    { id: 87, value: '华氏度 / ℉', label: '华氏度 / ℉' },
    { id: 88, value: '开尔文 / K', label: '开尔文 / K' },
    { id: 89, value: '吨 / t', label: '吨 / t' },
    { id: 90, value: '摄氏度 / °C', label: '摄氏度 / °C' },
    { id: 91, value: '毫帕 / mPa', label: '毫帕 / mPa' },
    { id: 92, value: '百帕 / hPa', label: '百帕 / hPa' },
    { id: 93, value: '千帕 / kPa', label: '千帕 / kPa' },
    { id: 94, value: '帕斯卡 / Pa', label: '帕斯卡 / Pa' },
    { id: 95, value: '毫克 / mg', label: '毫克 / mg' },
    { id: 96, value: '克 / g', label: '克 / g' },
    { id: 97, value: '千克 / kg', label: '千克 / kg' },
    { id: 98, value: '牛 / N', label: '牛 / N' },
    { id: 99, value: '毫升 / mL', label: '毫升 / mL' },
    { id: 100, value: '升 / L', label: '升 / L' },
    { id: 101, value: '立方毫米 / mm³', label: '立方毫米 / mm³' },
    { id: 102, value: '立方厘米 / cm³', label: '立方厘米 / cm³' },
    { id: 103, value: '立方千米 / km³', label: '立方千米 / km³' },
    { id: 104, value: '立方米 / m³', label: '立方米 / m³' },
    { id: 105, value: '公顷 / h㎡', label: '公顷 / h㎡' },
    { id: 106, value: '平方厘米 / c㎡', label: '平方厘米 / c㎡' },
    { id: 107, value: '平方毫米 / m㎡', label: '平方毫米 / m㎡' },
    { id: 108, value: '平方千米 / k㎡', label: '平方千米 / k㎡' },
    { id: 109, value: '平方米 / ㎡', label: '平方米 / ㎡' },
    { id: 110, value: '纳米 / nm', label: '纳米 / nm' },
    { id: 111, value: '微米 / μm', label: '微米 / μm' },
    { id: 112, value: '毫米 / mm', label: '毫米 / mm' },
    { id: 113, value: '厘米 / cm', label: '厘米 / cm' },
    { id: 114, value: '分米 / dm', label: '分米 / dm' },
    { id: 115, value: '千米 / km', label: '千米 / km' },
    { id: 116, value: '米 / m', label: '米 / m' },
  ];
  const componentsTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'MQTT', label: 'MQTT' },
    { id: '2', value: 'HTTP', label: 'HTTP' },
  ];
  const agreementTypeEnums: DefaultOptionType[] = [
    { id: '1', value: 'MQTT', label: 'MQTT' },
    { id: '2', value: 'HTTP', label: 'HTTP' },
    { id: '3', value: 'MODBUS', label: 'MODBUS' },
  ];
  const requestMethodEnums: DefaultOptionType[] = [
    { id: '1', value: 'GET', label: 'Get' },
    { id: '2', value: 'POST_FORM_DATA', label: 'Post(application/form-data)' },
    { id: '3', value: 'POST_JSON', label: 'Post(application/json)' },
  ];
  const flowDirectionEnums: DefaultOptionType[] = [
    { id: '1', value: 'UPLINK', label: '上行' },
    { id: '2', value: 'DOWNWARD', label: '下行 ' },
  ];

  const [propertyList, setPropertyList] = useState<DefaultOptionType[]>([]); // 属性
  const [eventList, setEventList] = useState<DefaultOptionType[]>([]); // 事件
  const [serviceList, setServiceList] = useState<DefaultOptionType[]>([]); // 服务
  const [networkList, setNetworkList] = useState<DefaultOptionType[]>([]); // 网络组件
  const [productList, setProductList] = useState<DefaultOptionType[]>([]); // 产品
  const [productTypeList, setProductTypeList] = useState<DefaultOptionType[]>([]); // 产品类型
  const [modbusUpAndDownConfigList, setModbusUpAndDownConfigList] = useState<DefaultOptionType[]>(
    [],
  ); // modbus 上下行配置
  const [mqttUpAndDownConfigList, setMqttUpAndDownConfigList] = useState<DefaultOptionType[]>([]); // mqtt 上下行配置
  const [httpUpAndDownConfigList, setHttpUpAndDownConfigList] = useState<DefaultOptionType[]>([]); // http 上下行配置

  // // 灌区列表/树
  // const getIrrigationListFunction = useCallback(async (params: any) => {
  //   const res = await getIrrigationList({ ...params, coordinatesType: coordinatesType });
  //   if (res.data) {
  //     setIrrigationTree(
  //       addTreeRoot(
  //         res.data.map((item: any) => ({
  //           ...item,
  //           id: item.id,
  //           title: item.name,
  //           key: item.id,
  //         })),
  //         '全部灌区',
  //         '-1',
  //         true,
  //       ),
  //     );
  //     setIrrigationList(
  //       res.data.map((item: any) => ({ ...item, id: item.id, label: item.name, value: item.id })),
  //     );
  //     return res.data;
  //   }
  // }, []);

  // 属性
  const getPropertyListFunction = useCallback(async (params: any) => {
    const res = await getPropertyList({ ...params, functionType: 'PROPERTY' });
    if (res.data) {
      setPropertyList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.functionName,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  // 事件
  const getEventListFunction = useCallback(async (params: any) => {
    const res = await getEventList({ ...params, functionType: 'EVENT' });
    if (res.data) {
      setEventList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.functionName,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  // 服务
  const getServiceListFunction = useCallback(async (params: any) => {
    const res = await getServiceList({ ...params, functionType: 'SERVICE' });
    if (res.data) {
      setServiceList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.functionName,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);
  // 网络组件
  const getNetworkListFunction = useCallback(async (params: any) => {
    const res = await getNetworkList({ ...params });
    if (res.data) {
      setNetworkList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  // 产品类型
  const getProductListFunction = useCallback(async (params: any) => {
    const res = await getProductList(params);
    if (res.data) {
      setProductList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);
  // 产品类型
  const getProductTypeListFunction = useCallback(async (params: any) => {
    const res = await getProductTypeList(params);
    if (res.data) {
      setProductTypeList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  // Modbus 上下行配置
  const getModbusUpAndDownConfigListFunction = useCallback(async (params: any) => {
    const res = await getModbusUpAndDownConfigList({ ...params, agreementType: 'MODBUS' });
    if (res.data) {
      setModbusUpAndDownConfigList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);
  // MQTT 上下行配置
  const getMqttUpAndDownConfigListFunction = useCallback(async (params: any) => {
    const res = await getMqttUpAndDownConfigList({ ...params, agreementType: 'MQTT' });
    if (res.data) {
      setMqttUpAndDownConfigList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);
  // HTTP 上下行配置
  const getHttpUpAndDownConfigListFunction = useCallback(async (params: any) => {
    const res = await getHttpUpAndDownConfigList({ ...params, agreementType: 'HTTP' });
    if (res.data) {
      setHttpUpAndDownConfigList(
        res.data.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      );
      return res.data;
    }
  }, []);

  return {
    functionTypeEnums,
    dataTypeEnums,
    eventTypeEnums,
    callMethodEnums,
    unitEnums,
    componentsTypeEnums,
    requestMethodEnums,
    agreementTypeEnums,
    flowDirectionEnums,

    propertyList,
    getPropertyListFunction,
    eventList,
    getEventListFunction,
    serviceList,
    getServiceListFunction,
    productList,
    getProductListFunction,
    productTypeList,
    getProductTypeListFunction,
    modbusUpAndDownConfigList,
    getModbusUpAndDownConfigListFunction,
    mqttUpAndDownConfigList,
    getMqttUpAndDownConfigListFunction,
    httpUpAndDownConfigList,
    getHttpUpAndDownConfigListFunction,
    networkList,
    getNetworkListFunction,
  };
}
