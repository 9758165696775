// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/workspace/09_hohai_space/hohai-web/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'D:/workspace/09_hohai_space/hohai-web/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "登录",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'D:/workspace/09_hohai_space/hohai-web/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/user/menu",
                "layout": false,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Menu' */'D:/workspace/09_hohai_space/hohai-web/src/pages/user/Menu'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/waterGate",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterGateBoard' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterGateBoard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/waterWorker",
        "layout": false,
        "name": "智慧水工",
        "icon": "icon-shuiligongchenganquanjiance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterWorker' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterWorker'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/oneKeySend",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OneKeySend' */'D:/workspace/09_hohai_space/hohai-web/src/pages/OneKeySend'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/account/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__UserCenter' */'D:/workspace/09_hohai_space/hohai-web/src/pages/account/UserCenter'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/account/messages",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__MessagesCenter' */'D:/workspace/09_hohai_space/hohai-web/src/pages/account/MessagesCenter'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/testArcgis",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testArcgis' */'D:/workspace/09_hohai_space/hohai-web/src/pages/testArcgis'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/testCesium",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testCesium' */'D:/workspace/09_hohai_space/hohai-web/src/pages/testCesium'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/testThree",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testThree' */'D:/workspace/09_hohai_space/hohai-web/src/pages/testThree'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/fullView",
        "layout": false,
        "routes": [
          {
            "path": "/fullView",
            "redirect": "/fullView/fullView",
            "exact": true
          },
          {
            "path": "/fullView/fullView",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FullView' */'D:/workspace/09_hohai_space/hohai-web/src/pages/FullView'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/patrol",
        "name": "运维巡检",
        "icon": "icon-yunweixunjian",
        "access": "canView",
        "routes": [
          {
            "path": "/patrol",
            "redirect": "/patrol/calendar",
            "exact": true
          },
          {
            "path": "/patrol/calendar",
            "access": "canView",
            "name": "巡查日历",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Patrol__PatrolCalendar' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Patrol/PatrolCalendar'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/patrol/batchTask",
            "access": "canView",
            "name": "批量任务",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Patrol__PatrolBatchTask' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Patrol/PatrolBatchTask'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/patrol/record",
            "access": "canView",
            "name": "巡查记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Patrol__PatrolRecord' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Patrol/PatrolRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/patrol/pointManage",
            "access": "canView",
            "name": "巡查点管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Patrol__PatrolPoint' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Patrol/PatrolPoint'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/patrol/evaluateManage",
            "access": "canView",
            "name": "管理评估",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Patrol__PatrolEvaluate' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Patrol/PatrolEvaluate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/repository",
        "name": "仓库管理",
        "icon": "icon-cangkuguanli",
        "access": "canView",
        "routes": [
          {
            "path": "/repository",
            "redirect": "/repository/cargo",
            "exact": true
          },
          {
            "path": "/repository/cargo",
            "access": "canView",
            "name": "货物管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryCargo' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryCargo'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/repository/inbound",
            "access": "canView",
            "name": "入库管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryInbound' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryInbound'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/repository/outbound",
            "access": "canView",
            "name": "出库管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryOutbound' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryOutbound'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/repository/inOutDetail",
            "access": "canView",
            "name": "出入库明细",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryInOutDetail' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryInOutDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/repository/inventory",
            "access": "canView",
            "name": "库存管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryInventory' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryInventory'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/repository/other",
            "access": "canView",
            "name": "其他管理",
            "routes": [
              {
                "path": "/repository/other/repositoryManage",
                "access": "canView",
                "name": "仓库管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/repository/other/cargoType",
                "access": "canView",
                "name": "货物类型管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryCargoType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryCargoType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/repository/other/cargoUnit",
                "access": "canView",
                "name": "货物单位管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryCargoUnit' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryCargoUnit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/repository/other/inboundType",
                "access": "canView",
                "name": "入库类型管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryInboundType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryInboundType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/repository/other/outboundType",
                "access": "canView",
                "name": "出库类型管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repository__RepositoryOutboundType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Repository/RepositoryOutboundType'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/project",
        "name": "交付工程管理",
        "icon": "icon-xiangmuguanli",
        "access": "canView",
        "routes": [
          {
            "path": "/project",
            "redirect": "/project/projectManage",
            "exact": true
          },
          {
            "path": "/project/projectManage",
            "access": "canView",
            "name": "项目管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__ProjectManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/ProjectManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/projectManageDetail",
            "access": "canView",
            "hideInMenu": true,
            "name": "项目管理详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__ProjectManage__detail' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/ProjectManage/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/checkProjectDetail",
            "access": "canView",
            "hideInMenu": true,
            "name": "项目验收详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__ProjectManage__checkDetail' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/ProjectManage/checkDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/projectManageFlow",
            "access": "canView",
            "hideInMenu": true,
            "name": "项目流程",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__ProjectManage__flowForm' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/ProjectManage/flowForm'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/taskManage",
            "access": "canView",
            "name": "任务管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__TaskManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/TaskManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/costAnalysis",
            "access": "canView",
            "name": "成本分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__CostAnalysis' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/CostAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/project/baseConfig",
            "name": "基础配置",
            "routes": [
              {
                "path": "/project/baseConfig",
                "redirect": "/project/baseConfig/projectType",
                "exact": true
              },
              {
                "path": "/project/baseConfig/projectType",
                "access": "canView",
                "name": "项目类型",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__ProjectType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/ProjectType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/project/baseConfig/costType",
                "access": "canView",
                "name": "成本类型",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__CostType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/CostType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/project/baseConfig/costSubclass",
                "access": "canView",
                "name": "成本分项",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__CostSubclass' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/CostSubclass'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/project/baseConfig/costUnit",
                "access": "canView",
                "name": "成本单位",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__CostUnit' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/CostUnit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/project/baseConfig/memberCost",
                "access": "canView",
                "name": "人员成本",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Project__MemberCost' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Project/MemberCost'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/system/tenant",
        "name": "租户管理",
        "access": "canView",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Tenant' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/Tenant'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/system",
        "name": "系统管理",
        "icon": "icon-xitongguanli",
        "access": "canView",
        "routes": [
          {
            "path": "/system",
            "redirect": "/system/menu",
            "exact": true
          },
          {
            "path": "/system/menu",
            "name": "菜单管理",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Menu' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/Menu'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/user",
            "name": "用户管理",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__User' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/User'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/role",
            "name": "角色管理",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Role' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/Role'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/unitType",
            "name": "采集设备型号",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__UnitType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/UnitType'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/deviceCommunicationTest",
            "name": "设备通讯测试",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__DeviceCommunicationTest' */'D:/workspace/09_hohai_space/hohai-web/src/pages/System/DeviceCommunicationTest'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/farmer",
        "name": "智慧灌区",
        "icon": "icon-hehushengtaijiance",
        "routes": [
          {
            "path": "/farmer",
            "redirect": "/farmer/autoIrr",
            "exact": true
          },
          {
            "path": "/farmer/visual",
            "name": "灌区可视化展示",
            "routes": [
              {
                "path": "/farmer/visual",
                "redirect": "/farmer/visual/mainVideo",
                "exact": true
              },
              {
                "path": "/farmer/visual/mainVideo",
                "name": "可视化展示",
                "layout": false,
                "target": "_blank",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__Visual__MainVideo' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/Visual/MainVideo'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/farmer/autoIrr",
            "name": "智能化灌溉系统",
            "access": "canView",
            "routes": [
              {
                "path": "/farmer/autoIrr",
                "redirect": "/farmer/autoIrr/autoIrr",
                "exact": true
              },
              {
                "path": "/farmer/autoIrr/autoIrr",
                "name": "智能灌溉",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__AutoIrr__AutoIrr' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/AutoIrr/AutoIrr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/autoIrr/strategy",
                "name": "控制策略配置",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__AutoIrr__Strategy' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/AutoIrr/Strategy'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/autoIrr/dataStatistics",
                "name": "数据统计",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__AutoIrr__DataStatistics' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/AutoIrr/DataStatistics'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/autoIrr/expertDecision",
                "name": "专家决策模型",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__AutoIrr__ExpertDecision' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/AutoIrr/ExpertDecision'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/autoIrr/nodeManage",
                "name": "节点管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__AutoIrr__NodeManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/AutoIrr/NodeManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/farmer/monitoringManage",
            "name": "信息化监控系统",
            "routes": [
              {
                "path": "/farmer/monitoringManage",
                "redirect": "/farmer/monitoringManage/pump",
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/pump",
                "name": "首部调度",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Pump' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Pump'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/gate",
                "name": "闸门调度",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Gate' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Gate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/pipe",
                "name": "管网监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Pipe' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Pipe'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/video",
                "name": "视频监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Video' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Video'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/weather",
                "name": "气象监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Weather' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Weather'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/soilMoisture",
                "name": "墒情监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__SoilMoisture' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/SoilMoisture'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/water",
                "name": "水质监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Water' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Water'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/insect",
                "name": "虫情监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Insect' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Insect'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/monitoringManage/safe",
                "name": "安全监控",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__MonitorManage__Safe' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/MonitorManage/Safe'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/farmer/generalManage",
            "name": "综合管理系统",
            "routes": [
              {
                "path": "/farmer/generalManage",
                "redirect": "/farmer/generalManage/baseInfoManage",
                "exact": true
              },
              {
                "path": "/farmer/generalManage/baseInfoManage",
                "name": "灌区基本信息",
                "routes": [
                  {
                    "path": "/farmer/generalManage/baseInfoManage",
                    "redirect": "/farmer/generalManage/baseInfoManage/irrigation",
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/irrigation",
                    "name": "灌区管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Irrigation' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Irrigation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/canal",
                    "name": "骨干工程管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Canal' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Canal'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/pumpRoom",
                    "name": "泵房管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__PumpRoom' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/PumpRoom'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/gateStation",
                    "name": "闸站管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__GateStation' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/GateStation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/planting",
                    "name": "种植单元管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Planting' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Planting'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/irrigationSystem",
                    "name": "灌溉系统管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__IrrigationSystem' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/IrrigationSystem'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/pipe",
                    "name": "田间渠道管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Pipe' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Pipe'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/site",
                    "name": "站点管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Site' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Site'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/siteType",
                    "name": "站点类型",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__SiteType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/SiteType'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/baseInfoManage/crop",
                    "name": "作物生长数据管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__BaseInfoManage__Crop' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/BaseInfoManage/Crop'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/farmer/generalManage/maintain",
                "name": "运行维护管理",
                "access": "canView",
                "routes": [
                  {
                    "path": "/farmer/generalManage/maintain",
                    "redirect": "/farmer/generalManage/maintain/pumpRoomRecord",
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/maintain/pumpRoomRecord",
                    "name": "首部调度记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Maintain__PumpRoomRecord' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Maintain/PumpRoomRecord'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/maintain/irrigateRecord",
                    "name": "灌溉记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Maintain__IrrigateRecord' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Maintain/IrrigateRecord'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/maintain/fertilizerRecord",
                    "name": "施肥设备操作记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Maintain__FertilizerRecord' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Maintain/FertilizerRecord'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/maintain/record/:siteType",
                    "name": "记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Maintain__Record' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Maintain/Record'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/maintain/maintainRecord",
                    "name": "设备维修记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Maintain__MaintainRecord' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Maintain/MaintainRecord'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/farmer/generalManage/deviceManage",
                "name": "设备管理",
                "routes": [
                  {
                    "path": "/farmer/generalManage/deviceManage",
                    "redirect": "/farmer/generalManage/deviceManage/deviceClass",
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/deviceManage/deviceClass",
                    "name": "设备分类",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__DeviceManage__DeviceClass' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/DeviceManage/DeviceClass'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/deviceManage/deviceType",
                    "name": "设备类型",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__DeviceManage__DeviceType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/DeviceManage/DeviceType'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/deviceManage/deviceManage",
                    "name": "设备台账",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__DeviceManage__DeviceManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/DeviceManage/DeviceManage'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/farmer/generalManage/waterManage",
                "name": "供用水管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__WaterManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/WaterManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/generalManage/userManage",
                "name": "用户及权限管理",
                "access": "canView",
                "routes": [
                  {
                    "path": "/farmer/generalManage/userManage",
                    "redirect": "/farmer/generalManage/userManage/role",
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/userManage/role",
                    "name": "角色管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__UserManage__Role' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/UserManage/Role'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/generalManage/userManage/farmers",
                    "name": "权限管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__UserManage__Farmers' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/UserManage/Farmers'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/farmer/generalManage/icon",
                "name": "图标库",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__GeneralManage__Icon' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/GeneralManage/Icon'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/farmer/cropManage",
            "name": "农产品溯源系统",
            "routes": [
              {
                "path": "/farmer/cropManage",
                "redirect": "/farmer/cropManage/growthManage",
                "exact": true
              },
              {
                "path": "/farmer/cropManage/growthManage",
                "name": "生长管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__GrowthManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/GrowthManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/cropManage/harvestManage",
                "name": "采收管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__HarvestManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/HarvestManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/cropManage/purchaserManage",
                "name": "采购商管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__Purchaser' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/Purchaser'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/cropManage/salesManage",
                "name": "销售管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__SalesManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/SalesManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/cropManage/h5",
                "name": "溯源档案",
                "layout": false,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__H5Page' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/H5Page'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/cropManage/traceabilityManage",
                "name": "溯源管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__CropManage__TraceabilityManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/CropManage/TraceabilityManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/farmer/tailWater",
            "name": "尾水活用系统",
            "routes": [
              {
                "path": "/farmer/tailWater",
                "redirect": "/farmer/tailWater/biologyManage",
                "exact": true
              },
              {
                "path": "/farmer/tailWater/biologyManage",
                "name": "生物管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__BiologyManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/BiologyManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/tailWater/mediumManage",
                "name": "人工介质管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__MediumManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/MediumManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/tailWater/facilityManage",
                "name": "设施管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__FacilityManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/FacilityManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/farmer/tailWater/patrol",
                "name": "任务管理",
                "access": "canView",
                "routes": [
                  {
                    "path": "/farmer/tailWater/patrol",
                    "redirect": "/farmer/tailWater/patrol/calendar",
                    "exact": true
                  },
                  {
                    "path": "/farmer/tailWater/patrol/calendar",
                    "name": "任务日历",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__Patrol__Calendar' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/Patrol/Calendar'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/tailWater/patrol/batchTask",
                    "name": "批量任务",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__Patrol__BatchTask' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/Patrol/BatchTask'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/tailWater/patrol/record",
                    "name": "任务记录",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__Patrol__Record' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/Patrol/Record'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/farmer/tailWater/patrol/pointManage",
                    "name": "地点管理",
                    "access": "canView",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Farmland__TailWater__Patrol__Point' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Farmland/TailWater/Patrol/Point'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/waterQuality",
        "name": "水质监测",
        "icon": "icon-xitongguanli",
        "access": "canView",
        "routes": [
          {
            "path": "/waterQuality",
            "redirect": "/waterQuality/dataReport",
            "exact": true
          },
          {
            "path": "/waterQuality/dataReport",
            "name": "数据报表",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterQuality__DataReport' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterQuality/DataReport'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/waterQuality/dataCompare",
            "name": "东太湖数据对比",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterQuality__DataCompare' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterQuality/DataCompare'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/waterQuality/DataCompareBST",
            "name": "bst数据对比",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterQuality__DataCompareBST' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterQuality/DataCompareBST'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/waterQuality/DataListBST",
            "name": "bst数据列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaterQuality__DataListBST' */'D:/workspace/09_hohai_space/hohai-web/src/pages/WaterQuality/DataListBST'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/rain",
        "name": "智慧雨情",
        "icon": "icon-weiboceyu",
        "routes": [
          {
            "path": "/rain",
            "redirect": "/rain/board",
            "exact": true
          },
          {
            "path": "/rain/board",
            "layout": false,
            "name": "",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rain__Board' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Rain/Board'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/rain/boardVideo",
            "layout": false,
            "name": "",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rain__BoardVideo' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Rain/BoardVideo'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/rain/division",
            "name": "行政区划",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rain__Division' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Rain/Division'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/workspace/09_hohai_space/hohai-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/displayCharts",
        "name": "数据展示",
        "icon": "icon-shujuzhanshi",
        "access": "canView",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DisplayCharts' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DisplayCharts'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/infrastructure",
        "name": "基础设施",
        "icon": "icon-xitongguanli",
        "access": "canView",
        "routes": [
          {
            "path": "/infrastructure",
            "redirect": "/infrastructure/dataDictionary",
            "exact": true
          },
          {
            "path": "/infrastructure/dataDictionary",
            "name": "数据字典",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Infrastructure__DataDictionary' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Infrastructure/DataDictionary'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/infrastructure/facilityType",
            "name": "基础设施类型",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Infrastructure__FacilityType' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Infrastructure/FacilityType'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/infrastructure/facility",
            "name": "设施类型台账",
            "access": "canView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Infrastructure__Facility' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Infrastructure/Facility'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/iot",
        "name": "IOT平台",
        "icon": "icon-shujujiaohuanpingtai",
        "access": "canView",
        "routes": [
          {
            "path": "/iot",
            "redirect": "/iot/basic",
            "exact": true
          },
          {
            "path": "/iot/basic",
            "name": "基础配置",
            "access": "canView",
            "routes": [
              {
                "path": "/iot/basic",
                "redirect": "/iot/basic/modelManage",
                "exact": true
              },
              {
                "path": "/iot/basic/modelManage",
                "name": "物模型管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Iot__Basic__ModelManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Iot/Basic/ModelManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/iot/basic/networkManage",
                "name": "网络组件管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Iot__Basic__NetworkManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Iot/Basic/NetworkManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/iot/product",
            "name": "产品管理",
            "access": "canView",
            "routes": [
              {
                "path": "/iot/product",
                "redirect": "/iot/product/typeManage",
                "exact": true
              },
              {
                "path": "/iot/product/typeManage",
                "name": "产品类型管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Iot__Product__TypeManage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Iot/Product/TypeManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/iot/product/manage",
                "name": "产品管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Iot__Product__Manage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Iot/Product/Manage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/iot/equipment",
            "name": "设备管理",
            "access": "canView",
            "routes": [
              {
                "path": "/iot/equipment",
                "redirect": "/iot/equipment/manage",
                "exact": true
              },
              {
                "path": "/iot/equipment/manage",
                "name": "设备管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Iot__Equipment__Manage' */'D:/workspace/09_hohai_space/hohai-web/src/pages/Iot/Equipment/Manage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/dataCenter",
        "name": "数据中台",
        "icon": "icon-shujujiaohuanpingtai",
        "routes": [
          {
            "path": "/dataCenter",
            "redirect": "/dataCenter/dataCenter",
            "exact": true
          },
          {
            "path": "/dataCenter/dataCenter",
            "name": "数据交换平台",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataCenter",
                "redirect": "/dataCenter/dataCenter/dataSource",
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/dataExchange",
            "name": "数据交换平台",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataExchange",
                "redirect": "/dataCenter/dataExchange/APITask",
                "exact": true
              },
              {
                "path": "/dataCenter/dataExchange/dataSource",
                "name": "数据源管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataExchange__DataSource' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataExchange/DataSource'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataExchange/taskTemplate",
                "name": "任务模板",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataExchange__TaskTemplate' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataExchange/TaskTemplate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataExchange/taskList",
                "name": "任务列表",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataExchange__TaskList' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataExchange/TaskList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataExchange/apiTask",
                "name": "API接入",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataExchange__APITask' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataExchange/APITask'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/dataPlatform",
            "name": "数据资产管理",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataPlatform",
                "redirect": "/dataCenter/dataPlatform/dataTag",
                "exact": true
              },
              {
                "path": "/dataCenter/dataPlatform/dataTag",
                "name": "数据资产标签",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataPlatform__DataTag' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataPlatform/DataTag'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataPlatform/dataDirectory",
                "name": "数据资产目录",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataPlatform__DataDirectory' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataPlatform/DataDirectory'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/dataService",
            "name": "数据服务管理",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataService",
                "redirect": "/dataCenter/dataService/dataService",
                "exact": true
              },
              {
                "path": "/dataCenter/dataService/dataService",
                "name": "服务管理",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataService__DataService' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataService/DataService'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataService/dataApplication",
                "name": "应用列表",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataService__DataApplication' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataService/DataApplication'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/dataResource",
            "name": "数据资源管理平台",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataResource",
                "redirect": "/dataCenter/dataResource/metadata",
                "exact": true
              },
              {
                "path": "/dataCenter/dataResource/metadata",
                "name": "元数据",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataResource__Metadata' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataResource/Metadata'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataResource/dataSearch",
                "name": "数据检索",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataResource__DataSearch' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataResource/DataSearch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataResource/dataMap",
                "name": "数据地图",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataResource__DataMap' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataResource/DataMap'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataResource/dataFlow",
                "name": "血缘流向",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataResource__DataFlow' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataResource/DataFlow'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/dataVisual",
            "name": "数据可视化平台",
            "access": "canView",
            "routes": [
              {
                "path": "/dataCenter/dataVisual",
                "redirect": "/dataCenter/dataVisual/chartConfig",
                "exact": true
              },
              {
                "path": "/dataCenter/dataVisual/chartConfig",
                "name": "图表配置",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataVisual__ChartConfig' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataVisual/ChartConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataVisual/chartConfig/Add",
                "name": "图表配置编辑",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataVisual__ChartConfig__components__AddForm' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataVisual/ChartConfig/components/AddForm.tsx'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataVisual/boardConfig",
                "name": "看板配置",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataVisual__BoardConfig' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataVisual/BoardConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/dataCenter/dataVisual/boardConfig/Add",
                "name": "看板配置编辑",
                "access": "canView",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataVisual__BoardConfig__components__AddForm' */'D:/workspace/09_hohai_space/hohai-web/src/pages/DataCenter/DataVisual/BoardConfig/components/AddForm.tsx'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
